<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<div class="box">
			<Title title="售后记录" />
			<div class="ct_box">
				<div class="search_status_box flex">
					<div class="search_box flex">
						<order_time @get_time="get_time" />
						<order_search @change_text="change_text" />
						<el-select v-model="category_id" @change="pageChange(1)" placeholder="请选择">
							<el-option v-for="item in resource_list" :key="item.id" :value="item.id"
								:label="item.title">
							</el-option>
						</el-select>
					</div>
					<orderStatus width="250" :status_list="status_list" @status_tab="status_tab" :order_status="status"
						:category_id="1" />
				</div>
				<el-table :data="tableData">
					<el-table-column width="200" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="sn" label="订单ID">
					</el-table-column>
					<el-table-column width="400" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="title" label="素材标题">
					</el-table-column>
					<el-table-column width="300" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="order_id" label="问题描述">
						<template slot-scope="scope">
							<td_remark :text="scope.row.desc" />
						</template>
					</el-table-column>
					<el-table-column width="180" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="guanggao_price" label="金额">
					</el-table-column>
					<el-table-column width="100" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" label="广告类型">
						<template slot-scope="scope">
							{{ resource_list[scope.row.category_id - 1].title }}
						</template>
					</el-table-column>
					<el-table-column width="200" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" label="状态">
						<template slot-scope="scope">
							<div class="untreated" @click="scope.row.check = !scope.row.check"
								v-if="scope.row.status == 0">
								未处理
							</div>
							<div v-if="scope.row.check" class="untreated_success" @click="upStatus(scope.row, 1)">
								已处理
							</div>
							<div v-if="scope.row.check" class="untreated_refuse" @click="upStatus(scope.row, 2)">
								拒绝处理
							</div>
							<div class="untreated_success" v-if="scope.row.status == 1">
								已处理
							</div>
							<div class="untreated_refuse" v-if="scope.row.status == 2">
								拒绝处理
							</div>
						</template>
					</el-table-column>
					<el-table-column width="200" label-class-name="custom-header-class" class-name="custom-column-cell"
						header-align="center" align="center" prop="ctime" label="提交时间">
					</el-table-column>

				</el-table>
				<!-- 分页器 -->
				<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
			</div>
		</div>
	</div>
</div></template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import { mapMutations } from 'vuex'
import { timeCut } from '@/util/timeCut'
import order_time from '@/components/order_time'
import order_search from '@/components/order_search'
import orderStatus from '@/components/orderStatus'
export default {
	components: {
		Title,
		order_time,
		order_search,
		orderStatus
	},
	created() {
		this.curlGet('/api/guanggao_info/init').then((res) => {
			if (res.data.code) {
				for (const key in res.data.data) {
					this.resource_list.push({
						title: res.data.data[key].title,
						id: res.data.data[key].id,
					})
				}
			}
		})
		this.get_list()
	},
	data() {
		return {
			time: [],
			category_id: '',
			search: '',
			resource_list: [],
			tableData: [],
			status_list: [
				{
					id: '',
					title: '全部',
				},
				{
					id: 0,
					title: '未处理',
				},
				{
					id: 1,
					title: '已处理',
				},
				{
					id: 2,
					title: '拒绝处理',
				}
			],
			status: '',
			Link: false, //查看链接弹窗
			order: false, //提交工单弹窗
			orderItem: {}, //工单信息
			serviceShow: false, //客服
			work: '',
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			linkList: {},
			fullscreenLoading: false, //loding
		}
	},
	methods: {
		status_tab(id) {
			this.status = id
			this.pageChange(1)
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		get_time(time) {
			this.time = time || []
			this.pageChange(1)
		},
		change_text(text) {
			this.search = text
			this.pageChange(1)
		},
		get_list() {
			this.fullscreenLoading = true
			// 取媒体类别

			let data = {}
			if (this.category_id !== '') {
				data.category_id = this.category_id
			}
			if (this.status.id !== '') {
				data.status = this.status.id
			}
			if (this.search != '') {
				data.search = this.search
			}
			if (this.time.length) {
				data.start_time = this.time[0] / 1000
				data.end_tiem = this.time[1] / 1000
			}
			this.curlGet('/api/users/guanggao_work/after_sale', data).then((res) => {
				if (res.data.code) {
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData = res.data.data.list
					console.log(res.data);
					this.tableData.forEach((item, index) => {
						if (item.status == 0) {
							this.$set(item, 'check', false)
						}
					})
				}
			})
			this.fullscreenLoading = false
		},
		// 修改订单状态
		upStatus(item, status) {
			this.curlPost('/api/users/guanggao_work/status_edit', {
				id: item.id,
				status: status,
			}).then((res) => {
				if (res.data.code) {
					this.get_list()
					return this.$message({
						message: '修改成功',
						type: 'success',
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/AfterSale';
@import '@/scss/placard_media';

::v-deep .el-input__inner {
	height: 30px;
}

::v-deep .el-input__icon {
	line-height: 30px;
}
</style>